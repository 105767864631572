import React, {MouseEventHandler} from "react";
import {Avatar, AvatarGroup, Card, CardActions, CardHeader, CardMedia, IconButton, Menu, MenuItem} from "@mui/material";
import {Meal} from "../model";
import {blueGrey, deepOrange, deepPurple} from "@mui/material/colors";
import {MoreVert} from "@mui/icons-material";
import {MealPlan} from "../../meal-plan/model";
import {User} from "../../user/model";
import {useDrag} from "react-dnd";

export interface Props {
    mealPlan?: MealPlan,
    meal: Meal;
    draggable?: boolean;
    onClick?: MouseEventHandler;
}

const userColors = [
    deepOrange,
    deepPurple,
    blueGrey
];

export default function MealCard({mealPlan, meal, draggable, onClick}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [{isDragging}, drag] = useDrag(() => ({
        type: 'meal',
        item: meal,
        canDrag: draggable,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const usersMap = new Map(mealPlan?.users.map(u => [u.id, u]));
    const userColorMap: Map<string, string> = new Map(mealPlan?.users.map((u, i) => [u.id, userColors[i % userColors.length][600]]));
    console.log({userColorMap});

    return (
        <>
            <Card sx={{maxWidth: 345}} onClick={onClick} ref={drag}>
                <CardHeader
                    action={
                        <IconButton aria-label="settings" onClick={handleClick}>
                            <MoreVert/>
                        </IconButton>
                    }
                    title={meal.title}
                    titleTypographyProps={{variant: 'subtitle1'}}
                />
                {meal.imageUrl && <CardMedia
                    component="img"
                    height="80"
                    image={meal.imageUrl}
                    alt={meal.title}
                />}
                {/*<CardContent>*/}
                {/*</CardContent>*/}
                {mealPlan?.users.map(u => u.id).some(id => !meal.userIds.includes(id)) &&
                    <CardActions disableSpacing>
                        <AvatarGroup max={3}>
                            {meal.userIds
                                .map(id => usersMap.get(id))
                                .filter(u => u)
                                .map(u => u as User)
                                .map(u => <Avatar key={u.id} alt={u.name} title={u.name}
                                                  sx={{width: '24px', height: '24px', bgcolor: userColorMap.get(u.id)}}>
                                    {u.name.charAt(0)}
                                </Avatar>)}
                        </AvatarGroup>
                        {/*<IconButton aria-label="add to favorites">*/}
                        {/*    <Favorite/>*/}
                        {/*</IconButton>*/}
                        {/*<IconButton aria-label="share">*/}
                        {/*    <Share />*/}
                        {/*</IconButton>*/}
                    </CardActions>
                }
            </Card>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem>Edit</MenuItem>
                <MenuItem>Delete</MenuItem>
                <MenuItem>Move</MenuItem>
                <MenuItem>Swap</MenuItem>
            </Menu>
        </>
    );
}