import React, {PropsWithChildren} from "react";
import {Box} from "@mui/material";
import {useDrop} from "react-dnd";
import {Meal} from "../model";

export interface Props {
    onDrop?: (meal: Meal) => void;
}

export default function MealDropZone({children, onDrop}: PropsWithChildren<Props>) {
    function handleDrop(meal: Meal) {
        console.log('handleDrop', meal);
        onDrop?.(meal);
    }

    const [collectedProps, drop] = useDrop({
        accept: 'meal',
        drop: handleDrop,
    });

    return (
        <Box ref={drop} sx={{width: '100%', height: '100%'}}>
            {children}
        </Box>
    );
}