export const nl = {
    appName: "Maaltijdplanner",
    dashboard: "Dashboard",
    today: "Vandaag",
    previous: "Vorige",
    next: "Volgende",
    mealType: "Maaltijdtype",
    mealTypes: {
        breakfast: "Ontbijt",
        lunch: "Lunch",
        dinner: "Diner",
        snack: "Tussendoor"
    },
    title: "Titel",
    link: "Link",
    save: "Opslaan",
    cancel: "Annuleren",
    delete: "Verwijderen",
    helperText: {
        required: "Vul een waarde in"
    },
    logout: "Uitloggen",
    myPlans: "Mijn menu's",
    mealPlan: "Menu",
    planner: "Planner",
    recipes: "Recepten",
    diet: "Mijn dieet",
    selectedUsers: "Wie",
    noMealsToday: "Nog geen maaltijden gepland voor vandaag."
}