import React, {useEffect} from "react";
import {Box, Checkbox, Chip, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import {User} from "../model";
import {nl} from "../../locale/nl";

export interface Props {
    users: User[],
    selected: User[],
    onChange?: (selectedUsers: User[]) => void,
}

export function SelectUsers({users, selected, onChange}: Readonly<Props>) {
    const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>(selected.map(user => user.id));

    useEffect(() => {
        setSelectedUserIds(selected.map(user => user.id));
    }, [selected]);

    const handleChange = (event: SelectChangeEvent<typeof selectedUserIds>) => {
        const {target: {value}} = event;
        console.log('SelectUsers.handleChange:', {value});
        // On autofill, we get a stringified value.
        if (typeof value !== 'string') {
            setSelectedUserIds(value);
            onChange?.(users.filter(user => value.includes(user.id)));
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedUserIds}
            onChange={handleChange}
            input={<OutlinedInput label={nl.selectedUsers}/>}
            renderValue={(selected) =>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                    {selectedUserIds.map(userId =>
                        <Chip key={userId} label={users.find(user => user.id === userId)?.name || userId}/>
                    )}
                </Box>
            }
            MenuProps={MenuProps}
        >
            {users.map(user => (
                <MenuItem key={user.id} value={user.id}>
                    <Checkbox checked={selectedUserIds.includes(user.id)}/>
                    <ListItemText primary={user.name}/>
                </MenuItem>
            ))}
        </Select>
    );
}