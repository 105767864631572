import React, {useEffect, useMemo, useState} from "react";
import {Autocomplete, createFilterOptions, debounce, TextField, TextFieldProps} from "@mui/material";
import {getRecentMeals} from "../service";
import {Meal} from "../model";
import {MealPlanId} from "../../meal-plan/model";
import {useAuth} from "react-oidc-context";

interface MealOption {
    title: string;
    meal?: Meal;
}

export interface Props {
    mealPlanId: MealPlanId;
    value?: string;
    onChange?: (option: Meal | string | null) => void;
    onEnter?: () => void;
    textFieldProps: TextFieldProps;
}

const DEFAULT_WAIT_DURING_TYPING = 400;
const filter = createFilterOptions<MealOption>();

export function MealAutocomplete({mealPlanId, value, onChange, onEnter, textFieldProps}: Readonly<Props>) {
    const auth = useAuth();
    const [selected, setSelected] = useState<MealOption | null>(value ? {title: value} : null);
    const [inputText, setInputText] = useState(value);
    const [options, setOptions] = useState<readonly MealOption[]>(selected ? [selected] : []);
    const [open, setOpen] = useState(false);

    const fetchOptions = useMemo(
        () => debounce((inputText, callback) => {
            if (mealPlanId) {
                getRecentMeals(mealPlanId, inputText, auth.user!!)
                    .then(meals => callback(meals.map(meal => ({meal, title: meal.title} as MealOption))))
                    .catch(console.error);
            }
        }, DEFAULT_WAIT_DURING_TYPING), [mealPlanId, auth]);


    useEffect(() => {
        if (value) {
            console.log('MealAutocomplete: useEffect', {value});
            setInputText(value);
            setSelected({title: value});
            setOptions([{title: value}] as MealOption[]);
        }
    }, [value]);

    useEffect(() => {
        let active = true;

        if (!inputText || inputText.trim().length < 2) {
            // Not enough input to search so just show the selected value
            setOptions(selected ? [selected] : []);
            return undefined;
        }

        fetchOptions(inputText.trim(), (results: readonly MealOption[]) => {
            if (active) {
                let newOptions: readonly MealOption[] = [];

                if (selected) {
                    const isExisting = results.some((option) => selected.title === option.title);
                    if (!isExisting) {
                        newOptions = [selected];
                    }
                }

                setOptions([...newOptions, ...results]);
            }
        });

        return () => {
            active = false;
        };
    }, [selected, inputText, fetchOptions]);

    function handleBlur() {
        let newSelected: MealOption | null;

        if (!inputText || inputText.trim().length === 0) {
            newSelected = null;
        } else {
            const existing = options.find((option) => inputText === option.title);
            if (existing) {
                newSelected = existing;
            } else {
                newSelected = {title: inputText};
            }
        }

        setSelected(newSelected);

        onChange?.(newSelected?.meal ?? newSelected?.title.trim() ?? inputText?.trim() ?? null)
    }

    function handleEnter(event: React.KeyboardEvent<HTMLElement>) {
        if (!open) {
            event.preventDefault();
            event.stopPropagation();

            handleBlur();

            onEnter?.();
        }
    }

    return (
        <Autocomplete
            value={selected}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(event, value) => {
                let newSelected: MealOption | null;
                if (value && typeof value === 'string' && value.trim().length > 0) {
                    newSelected = {
                        title: value,
                    };
                } else if (value && typeof value === 'object' && value.title) {
                    newSelected = value;
                } else {
                    newSelected = null;
                }

                setSelected(newSelected);

                onChange?.(newSelected?.meal ?? newSelected?.title.trim() ?? null);
            }}
            onInputChange={(event, newInputValue) => setInputText(newInputValue)}
            onBlur={handleBlur}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const {inputValue} = params;

                if (inputValue && inputValue.trim().length > 0) {
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (!isExisting) {
                        filtered.unshift({
                            title: inputValue,
                        });
                    }
                }

                return filtered;
            }}
            fullWidth
            handleHomeEndKeys
            options={options}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            freeSolo
            renderInput={(params) => (
                <TextField {...textFieldProps}
                           {...params}
                           onKeyDown={e => e.key === 'Enter' && handleEnter(e)}/>
            )}
        />
    );
}