import {
    Box,
    Button,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {CalendarViewWeek, FoodBank, Inbox, Logout, MenuBook} from "@mui/icons-material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "react-oidc-context";
import {MealPlan} from "../../meal-plan/model";
import {nl} from "../../locale/nl";

export interface Props {
    mealPlans?: MealPlan[];
    open: boolean;
    onClose?: () => void;
}

export function SideBar({mealPlans, open, onClose}: Props) {
    const auth = useAuth();
    const theme = useTheme();
    const permanent = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();

    const drawerWidth = 170;

    function handleLogout() {
        auth.signoutRedirect();
    }

    return (
        <Drawer
            variant={permanent ? 'permanent' : 'temporary'}
            open={permanent || open}
            onClose={onClose}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
            }}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate('/')} title={nl.dashboard}>
                        <ListItemIcon>
                            <Inbox/>
                        </ListItemIcon>
                        <ListItemText primary={nl.dashboard}/>
                    </ListItemButton>
                </ListItem>
                {mealPlans?.map(mealPlan => (
                    <ListItem key={mealPlan.id} disablePadding>
                        <ListItemButton onClick={() => navigate(`/plan/${mealPlan.id}`)} title={nl.planner}>
                            <ListItemIcon>
                                <CalendarViewWeek/>
                            </ListItemIcon>
                            <ListItemText primary={nl.planner}/>
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(`/recipes`)} title={nl.recipes}>
                        <ListItemIcon>
                            <MenuBook/>
                        </ListItemIcon>
                        <ListItemText primary={nl.recipes}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(`/diet`)} title={nl.diet}>
                        <ListItemIcon>
                            <FoodBank/>
                        </ListItemIcon>
                        <ListItemText primary={nl.diet}/>
                    </ListItemButton>
                </ListItem>
            </List>
            <Box>
                {auth.user?.profile.email}
                <Button onClick={handleLogout}><Logout/>{nl.logout}</Button>
            </Box>
        </Drawer>
    );
}