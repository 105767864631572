import React, {useEffect, useState} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import './App.css';

import dayjs from 'dayjs';
import 'dayjs/locale/nl' // import locale
import localeData from 'dayjs/plugin/localeData'
import {Outlet} from "react-router-dom";
import {Box, createTheme, CssBaseline, ThemeProvider, Toolbar} from "@mui/material";
import {nlNL} from "@mui/material/locale";
import {SideBar} from './common/components/SideBar';
import {MealPlan} from "./meal-plan/model";
import {getMealPlans} from "./meal-plan/service";
import {hasAuthParams, useAuth} from "react-oidc-context";
import {BottomBar} from "./common/components/BottomBar";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

const theme = createTheme(
    {},
    nlNL,
);

function App() {
    dayjs.extend(localeData);
    dayjs.locale('nl');

    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    const [mealPlans, setMealPlans] = useState<MealPlan[]>();
    const [sideBarOpen, setSideBarOpen] = useState(false);

    useEffect(() => {
        if (auth.isAuthenticated) {
            getMealPlans(auth.user?.access_token)
                .then(setMealPlans)
                .catch(console.error);
        }
    }, [auth]);

    // automatically sign-in
    React.useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.startSilentRenew();

            if (auth.user?.expired) {
                auth.signinSilent();
            } else {
                auth.signinRedirect();
            }
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Logging in...</div>;
        case "signoutRedirect":
            return <div>Logging out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Authentication error: {auth.error.message}</div>;
    }

    if (!auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }

    function handleMenuClick() {
        console.log('menu clicked');
        setSideBarOpen(prevState => !prevState);
    }

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                <DndProvider backend={HTML5Backend}>
                    <Box sx={{display: 'flex'}}>
                        <CssBaseline/>
                        <SideBar mealPlans={mealPlans} open={sideBarOpen} onClose={() => setSideBarOpen(false)}/>
                        <Box component="main" sx={{flexGrow: 1, p: 1, marginBottom: '54px'}}>
                            <Outlet/>
                        </Box>
                        <BottomBar mealPlans={mealPlans} onMenuClick={handleMenuClick}/>
                    </Box>
                </DndProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
