import {baseUrl} from "../common/service";
import {LocalDate} from "../common/datetime";
import {Meal, SuggestedImage} from "./model";
import {MealPlanId} from "../meal-plan/model";
import {User} from "oidc-client-ts";

export async function getMeals(mealPlanId: MealPlanId, from: LocalDate, to: LocalDate, access_token: string | undefined): Promise<Meal[]> {
    return fetch(`${baseUrl}/plans/${mealPlanId}/meals?from=${from}&to=${to}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }).then(response => response.json()).then(data => data as Meal[]);
}

export async function getRecentMeals(mealPlanId: MealPlanId, q: string, user: User): Promise<Meal[]> {
    console.log("suggestMeals: ", {mealPlanId, q});
    return fetch(`${baseUrl}/plans/${mealPlanId}/meals/recent?q=${q}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + user.access_token
        },
    }).then(response => response.json()).then(data => data as Meal[]);
}

export async function saveMeal(meal: Meal, access_token: string | undefined): Promise<any> {
    console.log("saveMeal: ", meal);

    return fetch(`${baseUrl}/plans/${meal.mealPlanId}/meals/${meal.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
        body: JSON.stringify(meal)
    });
}

export async function deleteMeal(meal: Meal, user: User): Promise<any> {
    console.log("deleteMeal: ", meal);
    return fetch(`${baseUrl}/plans/${meal.mealPlanId}/meals/${meal.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.access_token
        },
        body: JSON.stringify(meal)
    });
}

export async function suggestImages(q: string, user: User): Promise<SuggestedImage[]> {
    console.log("suggestImages: ", {q});
    return fetch(`${baseUrl}/images/suggest?q=${q}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + user.access_token
        },
    }).then(response => response.json()).then(data => data as SuggestedImage[]);
}