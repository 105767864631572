import {BottomNavigation, BottomNavigationAction, Paper, useMediaQuery, useTheme} from "@mui/material";
import {CalendarViewWeek, Inbox, Menu, MenuBook} from "@mui/icons-material";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {MealPlan} from "../../meal-plan/model";
import {nl} from "../../locale/nl";

export interface Props {
    mealPlans?: MealPlan[];
    onMenuClick?: () => void;
}

export function BottomBar({mealPlans, onMenuClick}: Props) {
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3} hidden={hidden}>
            <BottomNavigation showLabels value={
                location.pathname === '/' ? 'dashboard' :
                location.pathname.startsWith('/plan') ? 'planner' :
                location.pathname.startsWith('/recipes') ? 'recipes' :
                'none'
            }>
                <BottomNavigationAction
                    icon={<Menu/>}
                    onClick={onMenuClick}
                />

                <BottomNavigationAction
                    value='dashboard'
                    label={nl.dashboard}
                    icon={<Inbox/>}
                    onClick={() => navigate('/')}
                />

                {mealPlans?.slice(0, 1).map(mealPlan => (
                    <BottomNavigationAction
                        value='planner'
                        key={mealPlan.id}
                        label={nl.planner}
                        icon={<CalendarViewWeek/>}
                        onClick={() => navigate(`/plan/${mealPlan.id}`)}
                    />
                ))}

                <BottomNavigationAction
                    value='recipes'
                    label={nl.recipes}
                    icon={<MenuBook/>}
                    onClick={() => navigate(`/recipes`)}
                />
            </BottomNavigation>
        </Paper>
    );
}