import {useNavigate, useParams} from "react-router-dom";
import {Meal, MealType} from "../model";
import MealCalendar from "../components/MealCalendar";
import dayjs, {Dayjs} from "dayjs";
import MealDialog from "../components/MealDialog";
import React, {useEffect, useState} from "react";
import {deleteMeal, getMeals, saveMeal} from "../service";
import {useAuth} from "react-oidc-context";
import {v4 as uuidv4} from "uuid";
import {MealPlan} from "../../meal-plan/model";
import {getMealPlan} from "../../meal-plan/service";

export default function PlannerPage() {
    const params = useParams();
    const navigate = useNavigate();
    const {mealPlanId} = params;

    const auth = useAuth();
    const [date, setDate] = useState<Dayjs>(() => dayjs(params.date as string) || dayjs());
    const [mealPlan, setMealPlan] = useState<MealPlan>();
    const [meals, setMeals] = useState<Meal[]>();
    const [meal, setMeal] = useState<Meal>();
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (mealPlan) {
            document.title = `${mealPlan?.name} - Maaltijdplanner`;
        } else {
            document.title = 'Maaltijdplanner';
        }
    }, [mealPlan]);

    useEffect(() => {
        navigate(`/plan/${mealPlanId}/` + date.format('YYYY-MM-DD'), {replace: true});
    }, [mealPlanId, date, navigate]);

    useEffect(() => {
        if (mealPlanId) {
            getMealPlan(mealPlanId, auth.user?.access_token)
                .then(setMealPlan)
                .catch(console.error);
        }
    }, [mealPlanId, auth]);

    useEffect(() => {
        if (mealPlanId && date) {
            const firstDateOfWeek = date.startOf('week');
            const lastDateOfWeek = firstDateOfWeek.add(6, 'day');
            setMeals(undefined);
            getMeals(mealPlanId, firstDateOfWeek.format('YYYY-MM-DD'), lastDateOfWeek.format('YYYY-MM-DD'), auth.user?.access_token)
                .then(setMeals)
                .catch(console.error);
        }
    }, [mealPlanId, date, auth]);

    function handleSelectDate(selectedDate: Dayjs | null) {
        if (selectedDate) {
            setDate(selectedDate);
        }
    }

    function handleSelectMeal(meal: Meal) {
        setMeal(meal);
        setDialogOpen(true);
    }

    function handleNewMeal(date: Dayjs, type: MealType) {
        const created = dayjs().toISOString();
        const newMeal: Meal = {
            id: uuidv4(),
            mealPlanId: mealPlan!!.id,
            date: date.format('YYYY-MM-DD'),
            userIds: mealPlan!!.users.map(user => user.id),
            title: '',
            mealType: type,
            createdAt: created,
            modifiedAt: created,
        };
        setMeal(newMeal);
        setDialogOpen(true);
    }

    function handleSave(meal: Meal) {
        saveMeal(meal, auth.user?.access_token)
            .then(() => {
                setDialogOpen(false);
                setMeal(undefined);
                setMeals(meals?.concat(meal));
            })
            .catch(console.error);
    }

    function handleCancel() {
        setDialogOpen(false);
    }

    function handleDelete(meal: Meal) {
        deleteMeal(meal, auth.user!!)
            .then(() => {
                setDialogOpen(false);
                setMeal(undefined);
                setMeals(meals?.filter(m => m.id !== meal.id));
            })
            .catch(console.error);
    }

    function handleMoveMeal(meal: Meal, targetDate: Dayjs, targetType: MealType) {
        console.log('handleMoveMeal', {meal, targetDate, targetType});

        meal.mealType = targetType;
        meal.date = targetDate.format('YYYY-MM-DD');

        saveMeal(meal, auth.user?.access_token)
            .then(() => {
                // TODO update meal list
            })
            .catch(console.error);
    }

    return (
        <>
            {mealPlan &&
                <MealCalendar date={dayjs(date)}
                              mealPlan={mealPlan}
                              meals={meals ?? []}
                              onSelectDate={handleSelectDate}
                              onSelectMeal={handleSelectMeal}
                              onNewMeal={handleNewMeal}
                              onMoveMeal={handleMoveMeal}
                />
            }
            {mealPlan && meal &&
                <MealDialog
                    mealPlan={mealPlan}
                    meal={meal}
                    open={dialogOpen}
                    onSaveClick={handleSave}
                    onCancelClick={handleCancel}
                    onDelete={handleDelete}/>
            }
        </>
    );
}