import React from "react";
import {Stack} from "@mui/material";
import {Meal} from "../model";
import MealCard from "./MealCard";
import {MealPlan} from "../../meal-plan/model";
import {useDrop} from "react-dnd";

export interface Props {
    mealPlans: MealPlan[];
    meals: Meal[];
    onMealClick?: (meal: Meal) => void;
    draggable?: boolean;
}

export default function MealList({mealPlans, meals, draggable, onMealClick}: Props) {
    const mealPlansMap = new Map(mealPlans.map(mp => [mp.id, mp]));

    return (
        <Stack spacing={1}>
            {meals.map(meal =>
                <MealCard key={meal.id}
                          mealPlan={mealPlansMap.get(meal.mealPlanId)}
                          meal={meal}
                          draggable={draggable}
                          onClick={() => onMealClick?.(meal)}
                />
            )}
        </Stack>
    );
}