import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import PlannerPage from "./meal/pages/PlannerPage";
import DashboardPage from "./dashboard/pages/DashboardPage";
import {AuthProvider, AuthProviderProps} from 'react-oidc-context';
import {WebStorageStateStore} from "oidc-client-ts";
import RecipesPage from "./recipe/pages/RecipesPage";

const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        errorElement: <div>404</div>,
        children: [
            {
                path: '',
                element: <DashboardPage/>
            },
            {
                path: '/plan/:mealPlanId',
                element: <PlannerPage/>
            },
            {
                path: '/plan/:mealPlanId/:date',
                element: <PlannerPage/>,
            },
            {
                path: '/recipes',
                element: <RecipesPage/>
            },
        ]
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const authConfig: AuthProviderProps = {
    authority: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_UiXRdBkDf',
    client_id: '23po6b87ln8pqu05vag0gv2qt4',
    redirect_uri: window.origin,
    post_logout_redirect_uri: window.origin,
    onSigninCallback: () => window.history.replaceState({}, document.title, window.location.pathname),
    automaticSilentRenew: true,
    revokeTokensOnSignout: false,
    userStore: new WebStorageStateStore({store: window.localStorage}),
};

root.render(
    <React.StrictMode>
        <AuthProvider {...authConfig}>
            <RouterProvider router={router}/>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
