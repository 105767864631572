import {LocalDate, OffsetDateTime} from "../common/datetime";
import {UserId} from "../user/model";
import {MealPlanId} from "../meal-plan/model";
import {RecipeId} from "../recipe/model";

export type MealId = string;

export type URI = string;

export interface Meal {
    id?: MealId;
    mealPlanId: MealPlanId;
    userIds: UserId[];
    date: LocalDate;
    title: string;
    mealType: MealType;
    recipeId?: RecipeId;
    link?: URI;
    imageUrl?: URI;
    notes?: string;
    createdAt: OffsetDateTime;
    modifiedAt: OffsetDateTime;
}

export function isMeal(obj: any): obj is Meal {
    return obj && (obj as Meal).mealPlanId !== undefined && (obj as Meal).title !== undefined;
}

export enum MealType {
    BREAKFAST = 'breakfast',
    LUNCH = 'lunch',
    DINNER = 'dinner',
    SNACK = 'snack',
}

export const mealTypes: MealType[] = [
    MealType.BREAKFAST,
    MealType.LUNCH,
    MealType.DINNER,
    MealType.SNACK
];

export interface SuggestedImage {
    imageUrl: string,
    width: number,
    height: number,
}