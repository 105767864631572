import {MealPlan, MealPlanId} from "./model";
import {baseUrl} from "../common/service";

export async function getMealPlan(id: MealPlanId, access_token: string | undefined): Promise<MealPlan> {
    return fetch(`${baseUrl}/plans/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }).then(response => response.json()).then(data => data as MealPlan);
}

export async function getMealPlans(access_token: string | undefined): Promise<MealPlan[]> {
    return fetch(`${baseUrl}/plans`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }).then(response => response.json()).then(data => data as MealPlan[]);
}