import {baseUrl} from "../common/service";
import {RecipePreview} from "./model";
import {User} from "oidc-client-ts";

export async function fetch_preview(link: string, user: User): Promise<RecipePreview> {
    console.log("preview: ", {link});
    return fetch(`${baseUrl}/recipes/preview?link=${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + user.access_token
        },
    }).then(response => response.json()).then(data => data as RecipePreview);
}