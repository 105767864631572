import React, {useEffect, useState} from "react";
import {MealPlan} from "../../meal-plan/model";
import {getMealPlans} from "../../meal-plan/service";
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {nl} from "../../locale/nl";
import {useAuth} from "react-oidc-context";
import {Today} from "../components/Today";

export default function DashboardPage() {
    const auth = useAuth();
    const [mealPlans, setMealPlans] = useState<MealPlan[]>();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = nl.dashboard + ' - ' + nl.appName;

        if (auth.isAuthenticated) {
            getMealPlans(auth.user?.access_token)
                .then(setMealPlans)
                .catch(console.error);
        }
    }, [auth])

    return (
        <>
            <Today mealPlans={mealPlans}/>
        </>
    );
};