import {
    Button,
    Card,
    CardHeader,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    ImageList,
    ImageListItem,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {isMeal, Meal, MealType, SuggestedImage} from "../model";
import {OpenInBrowser} from "@mui/icons-material";
import dayjs from "dayjs";
import {nl} from "../../locale/nl";
import {MealAutocomplete} from "./MealAutocomplete";
import {useAuth} from "react-oidc-context";
import {MealPlan} from "../../meal-plan/model";
import {User} from "../../user/model";
import {RecipePreview} from "../../recipe/model";
import {SelectUsers} from "../../user/components/SelectUsers";
import {fetch_preview} from "../../recipe/service";
import {suggestImages} from "../service";
import {ImagePicker} from "../../common/components/ImagePicker";

export interface Props {
    mealPlan: MealPlan;
    meal: Meal;
    open: boolean;
    onSaveClick?: (meal: Meal) => void;
    onCancelClick?: () => void;
    onDelete?: (meal: Meal) => void;
}

interface FormState {
    type: MealType,
    users: User[],
    title: string,
    link: string,
}

function isValid(form: FormState) {
    return form.title.trim() !== '';
}

function fillFormFromMeal(mealPlan: MealPlan, meal: Meal): FormState {
    return {
        type: meal.mealType,
        users: mealPlan.users.filter(user => meal.userIds.includes(user.id)),
        title: meal?.title || '',
        link: meal?.link ?? '',
    };
}

export default function MealDialog({mealPlan, meal, open, onSaveClick, onCancelClick, onDelete}: Readonly<Props>) {
    const auth = useAuth();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [form, setForm] = useState<FormState>({
        type: meal.mealType,
        users: mealPlan.users.filter(user => meal.userIds.includes(user.id)) || mealPlan.users,
        title: '',
        link: '',
    });
    let newForm = form;

    const titleField = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (mealPlan && meal) {
            setForm(fillFormFromMeal(mealPlan, meal));

            setTimeout(() => {
                titleField.current?.focus();
            }, 500);
        }
    }, [mealPlan, meal]);

    function handleSave() {
        if (!isValid(newForm)) {
            return;
        }
        onSaveClick?.({
            ...meal,
            mealType: form.type,
            title: form.title,
            userIds: form.users.map(user => user.id),
            link: form.link,
            imageUrl: preview?.imageUrl,
        } as Meal);
    }

    function handleDelete() {
        onDelete?.(meal);
    }

    function handleAutocomplete(selected: Meal | string | null) {
        console.log('MealDialog: handleAutocomplete', selected);
        if (!selected || typeof selected === 'string') {
            newForm = {...form, title: selected ?? ''};
            setForm(newForm);
        } else if (isMeal(selected)) {
            // Copy selected meal
            newForm = {...fillFormFromMeal(mealPlan, selected), type: form.type};
            setForm(newForm);
        }
    }

    function handleMealType(event: SelectChangeEvent<MealType>) {
        console.log('handleMealType', event.target.value);
        setForm({...form, type: event.target.value as MealType});
    }

    function handleSelectUsers(users: User[]) {
        console.log('handleSelectUsers', users);
        setForm({...form, users});
    }

    const [previewingLink, setPreviewingLink] = useState<URL | undefined>(undefined);
    const [preview, setPreview] = useState<RecipePreview | undefined>(undefined);

    useEffect(() => {
        let url: URL | undefined = undefined;
        if (form.link) {
            try {
                url = new URL(form.link);
                if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                    url = undefined;
                }
            } catch (e) {
                console.error('Invalid URL', form.link);
            }
        }

        if (url === previewingLink || url?.href === previewingLink?.href) {
            return;
        }

        setPreviewingLink(url);
    }, [form]);

    useEffect(() => {
        const debounce = setTimeout(() => {
            setPreview(undefined);

            if (previewingLink && auth.user) {
                fetch_preview(previewingLink.href, auth.user)
                    .then(data => {
                        setPreview(data);
                        setForm(f => ({...f, title: data.title}));
                    })
                    .catch(console.error);
            }
        });
        return () => clearTimeout(debounce);
    }, [previewingLink, auth]);

    const [suggestedImages, setSuggestedImages] = useState<SuggestedImage[] | undefined>(undefined);

    useEffect(() => {
        const debounce = setTimeout(() => {
            if (form && form.title && auth.user) {
                suggestImages(form.title, auth.user)
                    .then(data => {
                        setSuggestedImages(data);
                    })
                    .catch(console.error);
            }
        });
        return () => clearTimeout(debounce);
    }, [form, auth]);

    function handleLink(event: ChangeEvent<HTMLInputElement>) {
        setForm(f => ({...f, link: event.target.value}));
    }

    return (
        <Dialog open={open} fullScreen={fullScreen} maxWidth="md" fullWidth>
            <DialogTitle variant="h6">
                {nl.mealTypes[form.type]} op {dayjs(meal.date).format('dd ll')}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} margin={1}>
                    <FormGroup>
                        <Select
                            value={form.type}
                            label={nl.mealType}
                            onChange={handleMealType}
                        >
                            {
                                Object.values(MealType).map((key) => {
                                    return <MenuItem key={key} value={key}>{nl.mealTypes[key as MealType]}</MenuItem>;
                                })
                            }
                        </Select>
                    </FormGroup>
                    <FormControl sx={{m: 1, width: 300}}>
                        <InputLabel id="demo-multiple-checkbox-label">{nl.selectedUsers}</InputLabel>
                        <SelectUsers users={mealPlan.users} selected={form.users} onChange={handleSelectUsers}/>
                    </FormControl>
                    <MealAutocomplete
                        textFieldProps={{
                            inputRef: titleField,
                            id: "title",
                            label: nl.title,
                            error: !form.title || form.title.trim() === '',
                            helperText: !form.title || form.title.trim() === '' ? nl.helperText.required : ''
                        }}
                        mealPlanId={meal.mealPlanId}
                        value={form.title}
                        onChange={handleAutocomplete}
                        onEnter={handleSave}/>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="link">{nl.link}</InputLabel>
                        <OutlinedInput
                            id="link"
                            type="text"
                            required fullWidth
                            value={form.link}
                            tabIndex={1}
                            onChange={handleLink}
                            onKeyDown={e => e.key === 'Enter' && handleSave()}
                            endAdornment={form.link?.trim() &&
                                <InputAdornment position="end">
                                    <Link href={form.link} target="_blank" rel="noreferrer">
                                        <OpenInBrowser/>
                                    </Link>
                                </InputAdornment>
                            }
                            label="Link"
                        />
                    </FormControl>
                    {preview && <Card sx={{maxWidth: 345}}>
                        <CardHeader
                            title={preview.title}
                            titleTypographyProps={{variant: 'subtitle1'}}
                        />
                        {preview.imageUrl && <CardMedia
                            component="img"
                            height="80"
                            image={preview.imageUrl}
                            alt={preview.title}
                        />}
                        {previewingLink && !preview && <CircularProgress/>}
                    </Card>}
                    {!preview && suggestedImages && <ImageList cols={3}>
                        <ImagePicker images={suggestedImages.map(i => i.imageUrl)}/>
                    </ImageList>}
                </Stack>
            </DialogContent>
            <DialogActions>
                {meal.id &&
                    <Button variant="text" color="error" tabIndex={6} onClick={handleDelete}>{nl.delete}</Button>}
                <Button variant="contained" color="primary" tabIndex={5} onClick={onCancelClick}>{nl.cancel}</Button>
                <Button variant="contained" color="primary" tabIndex={4} onClick={handleSave}
                        disabled={!isValid}>{nl.save}</Button>
            </DialogActions>
        </Dialog>
    );
}