import React from "react";
import {Box, Divider, Grid, IconButton, Paper, Toolbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Add, CalendarToday, NavigateBefore, NavigateNext} from "@mui/icons-material";

import dayjs, {Dayjs} from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import MealList from "./MealList";
import {Meal, MealType, mealTypes} from "../model";
import {DatePicker} from "@mui/x-date-pickers";

import {nl} from '../../locale/nl';
import {MealPlan} from "../../meal-plan/model";
import MealDropZone from "./MealDropZone";

export interface Props {
    date: Dayjs;
    mealPlan: MealPlan;
    meals: Meal[];
    onSelectDate?: (date: Dayjs | null) => void;
    onNewMeal?: (date: Dayjs, type: MealType) => void;
    onMoveMeal?: (meal: Meal, targetDate: Dayjs, targetType: MealType) => void;
    onSelectMeal?: (meal: Meal) => void;
}

dayjs.extend(duration);
dayjs.extend(localizedFormat);

function calculateWeekDates(date: dayjs.Dayjs) {
    const firstDateOfWeek = date.startOf('week');
    return Array(7).fill(null)
        .map((_, index) => firstDateOfWeek.add(dayjs.duration({days: index})));
}

export default function MealCalendar(
    {
        date,
        mealPlan,
        meals,
        onSelectDate,
        onNewMeal,
        onMoveMeal,
        onSelectMeal
    }: Props) {
    const theme = useTheme();
    const fitsWeek = useMediaQuery(theme.breakpoints.up('lg'));
    const mode = fitsWeek ? 'week' : 'day';
    const columns = mode === 'week' ? calculateWeekDates(date) : [date];

    function handlePrev() {
        onSelectDate?.(date.subtract(1, mode));
    }

    function handleNext() {
        onSelectDate?.(date.add(1, mode));
    }

    function handleToday() {
        onSelectDate?.(dayjs());
    }

    function handleMove(meal: Meal, date: Dayjs, type: MealType) {
        console.log('handleMove', {meal,date, type});
        onMoveMeal?.(meal, date, type);
    }

    return (
        <>
            <Toolbar variant="dense">
                <IconButton onClick={handleToday} title={nl.today}><CalendarToday/></IconButton>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <IconButton onClick={handlePrev} title={nl.previous}><NavigateBefore/></IconButton>
                <IconButton onClick={handleNext} title={nl.next}><NavigateNext/></IconButton>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <DatePicker value={columns[0]} onChange={onSelectDate} displayWeekNumber/>
                {columns.length > 1 && <>
                    &nbsp;-&nbsp;
                    <DatePicker value={columns[columns.length - 1]} onChange={onSelectDate}/>
                </>}
            </Toolbar>

            <Grid container spacing={2} columns={columns.length}>
                {
                    columns.map(column => {
                            return <Grid item xs={1}
                                         key={column.format('YYYY-MM-DD')}
                                         title={column.format('LL')}
                                         style={{textAlign: "center"}}>
                                <Typography variant="h6">{column.format('dd LL')}</Typography>
                            </Grid>;
                        }
                    )
                }
            </Grid>

            <Divider/>

            {
                mealTypes.map(mealType =>
                    <Grid container spacing={2} columns={columns.length} key={mealType}>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <Typography variant="h6">{nl.mealTypes[mealType]}</Typography>
                        </Grid>
                        {
                            columns.map(column => {
                                const mealDate = column.format('YYYY-MM-DD');

                                const mealsForDateAndType = meals
                                    .filter(meal => meal.date === mealDate)
                                    .filter(meal => meal.mealType === mealType)
                                    .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

                                return <Grid item xs={1} key={mealDate} style={{minHeight: '120px'}}>
                                    <Box style={{position: 'relative', minHeight: '100%'}}>
                                        <MealDropZone onDrop={meal => handleMove(meal, column, mealType)}>
                                            <MealList mealPlans={[mealPlan]}
                                                      meals={mealsForDateAndType}
                                                      draggable={true}
                                                      onMealClick={onSelectMeal}/>
                                        </MealDropZone>
                                        <IconButton style={{position: 'absolute', right: 0, bottom: 0}}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        onNewMeal?.(column, mealType);
                                                    }}>
                                            <Add/>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            })
                        }
                    </Grid>
                )
            }
        </>
    );
}