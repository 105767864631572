
import {Box, Button, MobileStepper, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface Props {
    images: string[];
    onPick?: (image: string) => void;
}

export function ImagePicker({images}: Props) {
    const theme = useTheme();
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        setSelected(0);
    }, [images]);

    const handleNext = () => {
        setSelected(prevSelected => (prevSelected + 1) % images.length);
    };

    const handleBack = () => {
        setSelected(prevSelected => (prevSelected - 1) % images.length);
    };

    const handleStepChange = (index: number) => {
      setSelected(index);
    };

    return (
        <Box sx={{maxWidth: 400, flexGrow: 1}}>
            <Slider
                dots={true}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            afterChange={handleStepChange}>
                {images.map((image, index) => (
                    <img
                        key={image}
                        src={image}
                        alt="image"
                        style={{width: '100%', height: '100%', objectFit: 'contain'}}
                    />
                ))}
            </Slider>
        </Box>
    );
}