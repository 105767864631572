import {Box, Card, CardContent, CardHeader, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "react-oidc-context";
import dayjs from "dayjs";
import {MealPlan} from "../../meal-plan/model";
import {Meal, mealTypes} from "../../meal/model";
import {getMeals} from "../../meal/service";
import {nl} from "../../locale/nl";
import MealList from "../../meal/components/MealList";

export interface Props {
    mealPlans?: MealPlan[];
}

export function Today({mealPlans}: Props) {
    const auth = useAuth();
    const theme = useTheme();

    const today = dayjs();
    const [meals, setMeals] = useState<Meal[]>();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isAuthenticated && mealPlans) {
            const from = today.format('YYYY-MM-DD');
            const to = from;
            Promise.all(mealPlans.map(mealPlan => getMeals(mealPlan.id, from, to, auth.user?.access_token)))
                .then(meals => setMeals(meals.flat()))
                .catch(console.error);
        }
    }, [auth, mealPlans])

    return (
        <Card>
            <CardHeader title={nl.today} subheader={today.format('LL')}/>
            <CardContent>
                {mealPlans && meals &&
                    mealTypes.map(mealType => {
                            const mealsOfType = meals.filter(meal => meal.mealType === mealType)
                                .sort((a, b) => a.createdAt.localeCompare(b.createdAt));
                            return mealsOfType.length > 0
                                ? <Box key={mealType}>
                                    <Typography variant="h6">{nl.mealTypes[mealType]}</Typography>
                                    <MealList mealPlans={mealPlans} meals={mealsOfType}/>
                                </Box> : null;
                        }
                    )}
                {meals && meals.length === 0 && <p>{nl.noMealsToday}</p>}
            </CardContent>
        </Card>
    );
}